import "@ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "@ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { CellClickedEvent, GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import { CACHE_LIFESPAN } from "global/components/UI/DataGrid/hooks/use-data-cache";
import { useCachedData } from "global/hook/cache/use-cached-data";
import MESSAGES from "global/messages";
import { noop } from "global/util/utils";
import {
  PropsWithChildren,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { GridActions } from "./components/GridActions";
import "./dataGrid.scss";
import { useGridCache } from "./hooks/use-grid-cache";
import { useGridDataFetch } from "./hooks/use-grid-data-fetch";
import { DataGridApi, DataGridProps, FilterConfig } from "./types";

const DEFAULT_GRID_HEIGHT = 300;
const DEFAULT_GRID_DATA_CACHE_MAX_AGE = 300;

var scrollTask: NodeJS.Timeout;

const DataGrid = <T,>(props: PropsWithChildren<DataGridProps<T>>) => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const dataApi = useRef<DataGridApi<T>>({
    cachedData: [],
    gridData: [],
    updateData: noop,
    fetchData: noop,
    scrollToIndex: noop,
  });
  const [activeRowIndex, setActiveRowIndex] = useState(-1);

  const hasUncontrolledData =
    props.rowsDataDef.uncontrolledDataFetchCall !== undefined;
  const hasActions =
    props.exportDef !== undefined || props.gridActions !== undefined;

  // Setup cache
  const cacheProps = {
    cacheKey: props.rowsDataDef.cacheProps?.cacheKey ?? "",
    expireAgeInSeconds:
      props.rowsDataDef.cacheProps?.expireAgeInSeconds ??
      DEFAULT_GRID_DATA_CACHE_MAX_AGE,
    lifespan: props.rowsDataDef.cacheProps?.lifespan ?? CACHE_LIFESPAN.SHORT,
  };

  const filterConfig = useCachedData<FilterConfig<T>>(
    props.rowsDataDef.filterCacheKey ?? "datagrid-filter-default"
  )[0];

  const {
    cacheData,
    dataCacheFill,
    cacheAgeProgress,
    canTriggerCacheRefresh,
    hasCachedData,
    showCacheDataActions,
  } = useGridCache<T>({
    cacheProps,
    isUncontrolledMode: hasUncontrolledData,
    refreshing: props.rowsDataDef.isFetchingData ?? false,
  });

  const onFetchDataEnd = (data: T[]) => {
    // Update grid data regardless of cache status
    setGridData(data);

    if (props.onRowDataChanged) {
      props.onRowDataChanged(data);
    }
  };

  // Setup data management
  const { refreshing, fetchData, updateData } = useGridDataFetch<T>({
    uncontrolledFetch: props.rowsDataDef.uncontrolledDataFetchCall,
    onDataChange: onFetchDataEnd,
    dataCacheFill,
  });

  // Event handlers
  const onRowClicked = useCallback(
    (event: CellClickedEvent) => {
      if (props.onRowClicked) {
        props.onRowClicked(event.data, event.column.getColId());
      }
    }, // eslint-disable-next-line
    [props.onRowClicked]
  );

  const [gridData, setGridData] = useState<Array<T>>(
    props.rowsDataDef.data ?? []
  );

  // Apply filter to data
  const filteredData = useCallback(
    (data: T[]): T[] => {
      const decorator =
        props.rowsDataDef.uncontrolledDataDecorator ??
        ((item: T, api: RefObject<DataGridApi<T>>) => item);

      return data
        .filter(filterConfig.test ?? (() => true))
        .map((item: T) => decorator(item, dataApi));
    },
    [filterConfig, props.rowsDataDef.uncontrolledDataDecorator]
  );

  useEffect(() => {
    if (hasUncontrolledData && hasCachedData)
      setGridData(filteredData(cacheData.data));
  }, [cacheData.data, filteredData]);

  useEffect(() => {
    dataApi.current = {
      cachedData: cacheData.data,
      gridData,
      fetchData,
      updateData,
      scrollToIndex: (ix: number) => {
        clearTimeout(scrollTask);
        scrollTask = setTimeout(() => {
          setActiveRowIndex((prev) => ix);
        }, 200);
      },
    };
    if (props.dataGridRef) {
      props.dataGridRef.current = dataApi.current;
    }
  }, [cacheData.data, gridData, fetchData, updateData, setActiveRowIndex]);

  useEffect(() => {
    if (activeRowIndex > 0) {
      gridApi?.ensureIndexVisible(activeRowIndex, "top");
      gridApi
        ?.getRowNode(activeRowIndex.toString())
        ?.setSelected(true, true, "api");
      setActiveRowIndex(-1);
    }
  }, [activeRowIndex]);

  // Grid configuration
  const gridOptions = {
    ...props.gridOptions,
    defaultColDef: {
      width: 120,
      sortable: true,
      resizable: true,
      flex: 1,
      ...props.gridOptions?.defaultColDef,
    },
    overlayNoRowsTemplate: MESSAGES.DATAGRID_NO_ROWS,
    columnDefs: props.columnDefs,
    rowHeight: 26,
    headerHeight: 36,
    animateRows: true,
    overlayLoadingTemplate: MESSAGES.DATAGRID_LOADING,
  };

  // Handle controlled data updates
  useEffect(() => {
    if (hasUncontrolledData) {
      // For uncontrolled mode with cache, use cached data
      if (props.rowsDataDef.cacheProps?.cacheKey && cacheData.data.length > 0) {
        setGridData(cacheData.data);
      }
      return;
    }

    if (props.rowsDataDef.isFetchingData) {
      gridApi?.showLoadingOverlay();
    } else {
      setGridData(props.rowsDataDef.data ?? []);
      if ((props.rowsDataDef.data ?? []).length === 0) {
        gridApi?.showNoRowsOverlay();
      } else {
        gridApi?.hideOverlay();
      }
    }
  }, [props.rowsDataDef, hasUncontrolledData, gridApi, cacheData.data]);

  const containerClassName = `ag-theme-alpine relativeGrid ${
    hasActions || showCacheDataActions ? "withGridActionsRow" : ""
  }`;

  return (
    <div
      className={containerClassName}
      style={{ height: props.height ?? DEFAULT_GRID_HEIGHT }}
    >
      <GridActions
        showActions={hasActions}
        showCacheActions={showCacheDataActions}
        exportDef={props.exportDef}
        gridActions={props.gridActions}
        refreshing={refreshing}
        canTriggerRefresh={canTriggerCacheRefresh}
        cacheAgeProgress={cacheAgeProgress}
        onRefresh={() => fetchData(false)}
      />

      <AgGridReact
        ref={props.gridRef}
        className={props.className}
        rowHeight={props.rowHeight}
        gridOptions={gridOptions}
        rowData={gridData}
        rowSelection="single"
        onCellClicked={onRowClicked}
        onRowDataUpdated={() => {
          if (props.onRowSelected) props.onRowSelected(undefined);
        }}
        onRowSelected={(event) => {
          if (props.onRowSelected && event.node.isSelected())
            props.onRowSelected(event.data);
        }}
        onGridReady={(event) => {
          setGridApi(event.api);
          if (props.onGridReady) props.onGridReady(event);
          fetchData(hasCachedData, false);
        }}
      />
    </div>
  );
};

export default DataGrid;
